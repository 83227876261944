
.LandingPage{
    background-color: black;
    height: 122vh;
    width: 100vw;
}

#logo1{
    margin-top: 10vh;
}
#title-LandingPage{
    font-family: 'Hurricane', cursive;
    color: aliceblue;
    font-size: 500%;
    margin-top: 0%;
}
.social-icon{
    display: inline-block;
    
}
#facebook-landingPage{
    max-height: 20vh;
    margin-right: 10px;

    
}

#instagram-landingPage{
    max-height: 20vh;
}

#Note{
    color: aliceblue;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
}

@media screen and (max-width: 800px) {

    .LandingPage{
        background-color: black;
        height: 120vh;
        width: 100vw;
    }
    
    #logo1{
        margin-top: 10vh;
        max-width: 40vh;
    }
  }